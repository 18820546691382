import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import CleanHTML from '../components/cleanHTML';
import Layout from '../components/layout';

/**
 * Privacy page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Privacy location={string} />
 */

const Privacy = ({ location }) => {
  const {
    contentfulLegal: {
      childContentfulLegalBodyTextNode: {
        childMarkdownRemark: { html },
      },
      title,
    },
  } = useStaticQuery(graphql`
    query Privacy {
      contentfulLegal(title: { glob: "Privacy *" }) {
        childContentfulLegalBodyTextNode {
          childMarkdownRemark {
            html
          }
        }
        title
      }
    }
  `);

  return (
    <Layout pageTitle="Privacy Policy" location={location}>
      <article
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyStart,
          base.w100,
          main.mt60,
          main.mb60,
          main.postLegal,
          main.postSingle,
          main.px60
        )}
      >
        <h1 className={cx(base.mb5, base.tc, theme.colorDark)}>{title}</h1>
        <section>
          <CleanHTML html={html} />
        </section>
      </article>
    </Layout>
  );
};

Privacy.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Privacy;
